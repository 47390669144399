/**
 * Konstanty ve frontend aplikaci.
 */

/**
 * Stav dat.
 */
export const DATA_STATE_NOT_READY   = 'NOT_READY'
export const DATA_STATE_PREPARING   = 'PREPARING'
export const DATA_STATE_READY       = 'READY'

/**
 * Stav objednavky.
 */
export const STAV_OBJEDNAVKY_VYTVORENA = "CREATED"          // CREATED
export const STAV_OBJEDNAVKY_CEKAJICI = "PENDING"           // PENDING
export const STAV_OBJEDNAVKY_ZAPLACENA = "PAID"             // PAID
export const STAV_OBJEDNAVKY_ZRUSENA = "CANCELLED"          // CANCELLED

/**
 * Stav platby.
 */
export const STAV_PLATBY_VYTVORENA = "CREATED"              // CREATED
export const STAV_PLATBY_CEKAJICI = "PENDING"               // PENDING
export const STAV_PLATBY_ZAPLACENA = "PAID"                 // PAID
export const STAV_PLATBY_ZRUSENA = "CANCELLED"              // CANCELLED
export const STAV_PLATBY_PREDAUTORIZOVANA = "AUTHORIZED"    // AUTHORIZED

/**
 * Druh platby
 */
 export const DRUH_PLATBY_PREVODEM = 1                      // Převodem
 export const DRUH_PLATBY_PLATEBNI_KARTA = 14               // Platební karta