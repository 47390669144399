/**
 * Store.
 */

import { mutationsAuth, stateAuth } from '@/store/auth.js'
import { mutationsComGate, stateComGate } from '@/store/comgate.js'
import { mutationsDataState, stateDataState } from '@/store/data_state.js'
import { mutationsError, stateError } from '@/store/error.js'
import { mutationsG11n, stateG11n } from '@/store/g11n.js'
import { mutationsObjednavka, stateObjednavka } from '@/store/objednavka.js'
import { mutationsPlatba, statePlatba } from '@/store/platba.js'
import { mutationsSetting, stateSetting } from '@/store/setting.js'
import { mutationsUser, stateUser } from '@/store/user.js'

import Vue from "vue"

export const mutations = {
    ...mutationsAuth,
    ...mutationsComGate,
    ...mutationsDataState,
    ...mutationsError,
    ...mutationsG11n,
    ...mutationsObjednavka,
    ...mutationsPlatba,
    ...mutationsSetting,
    ...mutationsUser
}

export const state = Vue.observable({
    ...stateAuth,
    ...stateComGate,
    ...stateDataState,
    ...stateError,
    ...stateG11n,
    ...stateObjednavka,
    ...statePlatba,
    ...stateSetting,
    ...stateUser
})