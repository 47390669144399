import { mutations, state } from '@/store'
import * as constants from '@/store/constants.js'
//import { testData } from '@/store/test_data.js'
import { sendRequest } from '@/lib/gms/api.js'
import { deepCopy } from '@/lib/gms/utils.js'
import { defaultsProdukt } from '@/store/produkt.js'

/**
 * Výchozí struktury pro entitu "objednavka"
 */
export const defaultsObjednavka = {
    objednavka_data: {
        cena_celkem: 0,
        cena_dopravy: 0,
        cena_zbozi: 0,
        dodaci_adresa: {
            cislo_popisne: null,
            mesto: null,
            jmeno: null,
            prijmeni: null,
            psc: null,
            ulice: null,
            zeme_id: 1
        },
        dorucit_na_jinou_adresu: false,
        druh_dopravy_id: 0,
        druh_platby_id: 0,
        fakturacni_adresa: {
            cislo_popisne: null,
            mesto: null,
            psc: null,
            ulice: null,
            zeme_id: 1
        },
        nakup_na_firmu: false,
        osobni_udaje: {
            email: null,
            email_confirm: null,
            jmeno: null,
            prijmeni: null,
            telefon: null,
        },
        produkty: [
            defaultsProdukt.produkt
        ],
        souhlasim_s_obchodnimi_podminkami: false,
        spolecnost: {
            dic: null,
            ic: null,
            nazev: null,
        }
    },

    objednavka_data_errors: {
        cena_celkem: null,
        cena_dopravy: null,
        cena_zbozi: null,
        "dodaci_adresa.cislo_popisne": null,
        "dodaci_adresa.mesto": null,
        "dodaci_adresa.jmeno": null,
        "dodaci_adresa.prijmeni": null,
        "dodaci_adresa.psc": null,
        "dodaci_adresa.ulice": null,
        "dodaci_adresa.zeme": null,
        dorucit_na_jinou_adresu: null,
        druh_dopravy: null,
        druh_platby: null,
        "fakturacni_adresa.cislo_popisne": null,
        "fakturacni_adresa.mesto": null,
        "fakturacni_adresa.psc": null,
        "fakturacni_adresa.ulice": null,
        "fakturacni_adresa.zeme": null,
        message: null,
        nakup_na_firmu: null,
        "osobni_udaje.email": null,
        "osobni_udaje.email_confirm": null,
        "osobni_udaje.jmeno": null,
        "osobni_udaje.prijmeni": null,
        "osobni_udaje.telefon": null,
        "produkt.pocet_ks": null,
        souhlasim_s_obchodnimi_podminkami: null,
        "spolecnost.dic": null,
        "spolecnost.ic": null,
        "spolecnost.nazev": null
    }
}

export const stateObjednavka = {
    /**
     * Data objednávky.
     * 
     *  @var {object}
     * 
        {
            cena_celkem: 489,
            cena_dopravy: 100,
            cena_zbozi: 389,
            dodaci_adresa: {
                cislo_popisne: 'cp',
                mesto: 'mesto',
                jmeno: 'jmeno',
                prijmeni: 'prijmeni',
                psc: 'psc',
                ulice: 'ulice',
                zeme: "Česká republika"
            },
            dorucit_na_jinou_adresu: false,
            fakturacni_adresa: {
                cislo_popisne: 'cp',
                mesto: 'mesto',
                psc: 'psc',
                ulice: 'ulice',
                zeme: "Česká republika"
            },
            nakup_na_firmu: false,
            osobni_udaje: {
                email: 'email',
                email_confirm: 'email conf',
                jmeno: 'jmeno',
                prijmeni: 'prijmeni',
                telefon: 'tel'
            },
            produkt: {
                pocet_ks: 1
            },
            souhlasim_s_obchodnimi_podminkami: false,
            spolecnost: {
                dic: 'dic',
                ic: 'ic',
                nazev: 'firma'
            },
            druh_dopravy: 1,
            druh_platby: 1
        }
    */
    objednavka_data: defaultsObjednavka.objednavka_data,
    //objednavka_data: testData.objednavka_test_data,

    /**
     * Chyby v datech detailu objednávky.
     * 
     *  @var {object}
     */
     objednavka_data_errors: defaultsObjednavka.objednavka_data_errors
}

export const mutationsObjednavka = {
    /**
     * Změní objednávku na platbu převodem.
     * 
     * @return {Promise}
     */
     changeOrderToBankTransfer(params) {
        return new Promise((resolve, reject) => {
            //mutations.setDataStateObjednavka(constants.DATA_STATE_PREPARING)

            sendRequest(
                '/api/order/change-to-bank-transfer',
                params
            )
            .then(response => {
                //mutations.setDataStateObjednavka(constants.DATA_STATE_READY)

                resolve(response.data)
            })
            .catch(errors => {
                //mutations.setDataStateObjednavka(constants.DATA_STATE_NOT_READY)

                reject(errors)
            })
        })
    },
   
    /**
     * Změní objednávku na zrušenou.
     * 
     * @return {Promise}
     */
     changeOrderToCancelled(params) {
        return new Promise((resolve, reject) => {
            //mutations.setDataStateObjednavka(constants.DATA_STATE_PREPARING)

            sendRequest(
                '/api/order/change-to-cancelled',
                params
            )
            .then(response => {
                //mutations.setDataStateObjednavka(constants.DATA_STATE_READY)

                resolve(response.data)
            })
            .catch(errors => {
                //mutations.setDataStateObjednavka(constants.DATA_STATE_NOT_READY)

                reject(errors)
            })
        })
    },

    /**
     * Uloží data objednávky na server.
     * 
     * @return {Promise}
     */
     createOrder(params) {
        return new Promise((resolve, reject) => {
            // Příprava dat
            delete params["druhy_dopravy"]
            delete params["druhy_platby"]
            delete params["objednavka_id"]
            delete params["zeme_nazev"]

            mutations.setDataStateObjednavka(constants.DATA_STATE_PREPARING)

            state.objednavka_data_errors = deepCopy(defaultsObjednavka.objednavka_data_errors)

            sendRequest(
                '/api/order/create',
                params
            )
            .then(response => {
                mutations.setDataStateObjednavka(constants.DATA_STATE_READY)

                resolve(response.data)
            })
            .catch(errors => {
                mutations.setDataStateObjednavka(constants.DATA_STATE_NOT_READY)

                reject(errors)
            })
        })
    },

    processOrderDataErrors(errors) {
        /*errors.forEach(error => {
            //console.log(error.field)

            state.objednavka_data_errors[error.field] = 
                (state.objednavka_data_errors[error.field] != null ? state.objednavka_data_errors[error.field] + "<br>" : "")
                + error.message
        })*/

        errors.forEach(error => {
            // error.field
            if (error.field != undefined) {
                state.objednavka_data_errors[error.field] = 
                    (state.objednavka_data_errors[error.field] != null ? state.objednavka_data_errors[error.field] + "<br>" : "")
                    + error.message
            }

            // error.message
            if (error.message != undefined) {
                state.objednavka_data_errors.message = error.message
            }
        })
    },

    /**
     * Připraví data objednávky na serveru.
     * 
     * @return {Promise}
     */
    readOrder(params) {
        return new Promise((resolve, reject) => {
            mutations.setDataStateObjednavka(constants.DATA_STATE_PREPARING)

            sendRequest(
                '/api/order/read',
                params
            )
            .then(response => {
                mutations.setDataStateObjednavka(constants.DATA_STATE_READY)

                state.objednavka_data = response.data

                resolve(response.data)
            })
            .catch(errors => {
                mutations.setDataStateObjednavka(constants.DATA_STATE_NOT_READY)

                reject(errors)
            })
        })
    },

    /**
     * Přepočítá cenu objednávky.
     * NOTICE Připraveno na pole produktů. Zatím se využívá 1. instance.
     * 
     */
    recalculatePrice( ) {
        //console.log("recalculatePrice( )")

        //let data = state.config.data
        let objednavka = state.objednavka_data
        let produkt = objednavka.produkty[0]
        let objednavka_produkt = objednavka.produkty[0]
        let cenaZbozi = produkt.cena_ks * objednavka_produkt.pocet_ks
        let cenaDopravy = objednavka.druhy_dopravy[objednavka.druh_dopravy_id].cena
        let cenaPlatba = objednavka.druhy_platby[objednavka.druh_platby_id].cena
        let cenaCelkem = cenaZbozi + cenaDopravy + cenaPlatba

        //console.log(cenaZbozi, cenaDopravy, cenaPlatba, cenaCelkem)
        
        objednavka.cena_zbozi = cenaZbozi
        objednavka.cena_dopravy = cenaDopravy
        objednavka.cena_celkem = cenaCelkem

        objednavka_produkt.cena_celkem = cenaZbozi
        objednavka_produkt.cena_ks = produkt.cena_ks
        objednavka_produkt.nazev = produkt.nazev
        objednavka_produkt.produkt_id = produkt.produkt_id
    }
}