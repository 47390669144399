//import { mutations, state } from '@/store'
//import * as constants from '@/store/constants.js'
//import { testData } from '@/store/test_data.js'
//import { sendRequest } from '@/lib/gms/api.js'
//import { deepCopy } from '@/lib/gms/utils.js'

/**
 * Výchozí struktury pro entitu "produkt"
 */
export const defaultsProdukt = {
    /**
     * Výchozí struktura pro entitu "produkt"
     */
    produkt: {
        cena_celkem: null,
        cena_ks: null,
        nazev: null,
        pocet_ks: 1,
        pocet_min: null,
        pocet_max: null,
        produkt_id: null
    }
}