//import { mutations, state } from '@/store'
//import * as constants from '@/store/constants.js'
//import { testData } from '@/store/test_data.js'
import { sendRequest } from '@/lib/gms/api.js'
//import { deepCopy } from '@/lib/gms/utils.js'

/**
 * Výchozí struktury pro entitu "objednavka"
 */
export const defaultsComGate = { }

export const stateComGate = { }

export const mutationsComGate = {
    /**
     * Pošle výsledek platby na eshop.
     * Výsledek platby = CANCELLED.
     * 
     * @return {Promise}
     */
     ComGateSendResultPaymentCancelled( ) {
        return new Promise((resolve, reject) => {
            sendRequest(
                '/api/comgate/send-result-payment-cancelled',
                null
            )
            .then(response => {
                resolve(response.data)
            })
            .catch(errors => {
                reject(errors)
            })
        })
    },

    /**
     * Pošle výsledek platby na eshop.
     * Výsledek platby = PAID.
     * 
     * @return {Promise}
     */
     ComGateSendResultPaymentPaid( ) {
        return new Promise((resolve, reject) => {
            sendRequest(
                '/api/comgate/send-result-payment-paid',
                null
            )
            .then(response => {
                resolve(response.data)
            })
            .catch(errors => {
                reject(errors)
            })
        })
    },

    /**
     * Pošle výsledek platby na eshop.
     * Výsledek platby = PENDING.
     * 
     * @return {Promise}
     */
     ComGateSendResultPaymentPending( ) {
        return new Promise((resolve, reject) => {
            sendRequest(
                '/api/comgate/send-result-payment-pending',
                null
            )
            .then(response => {
                resolve(response.data)
            })
            .catch(errors => {
                reject(errors)
            })
        })
    }
}