import * as constants from '@/store/constants.js'
import { /*mutations,*/ state } from '@/store'

export const stateDataState = {
    // Aktuální stav dat
    data_state: {
        config: {
            state: constants.DATA_STATE_NOT_READY
        },

        objednavka: {
            state: constants.DATA_STATE_READY
        },

        users: {
            state: constants.DATA_STATE_NOT_READY
        }
    }
}

export const mutationsDataState = {
    setDataStateConfig(newState) {
        state.data_state.config.state = newState
    },

    setDataStateObjednavka(newState) {
        state.data_state.objednavka.state = newState
    },

    setDataStateUsers(newState) {
        state.data_state.users.state = newState
    }
}