import { /*mutations,*/ state } from "@/store"

export const defaultsG11n = { }

export const stateG11n = {
    /**
     * Data Auth.
     * 
     *  @var {object}
     */
    g11n: {
        /**
         * Locale.
         * 
         *  @var {string}    "en" | "cs" | "en-GB" | "cs-CZ" | ...
         */
        locale: null
    }
}

export const mutationsG11n = {
    /**
     * Vrací locale.
     * 
     * @return {string}
     */
    getLocale( ) {
        return state.g11n.locale
    },

    /**
     * Nastaví locale.
     * 
     * @param {string} locale
     */
     setLocale(locale) {
        state.g11n.locale = locale
    },
}