import { /*mutations,*/ state } from '@/store'
//import * as constants from '@/store/constants.js'
//import { testData } from '@/store/test_data.js'
import { sendRequest } from '@/lib/gms/api.js'
//import { deepCopy } from '@/lib/gms/utils.js'

/**
 * Výchozí struktury pro entitu "objednavka"
 */
export const defaultsPlatba = {
    /*platba_data_errors: {
        message: null
    }*/
}

export const statePlatba = {
    /**
     * Chyby v datech detailu platby.
     * 
     *  @var {object}
     */
     //platba_data_errors: defaultsPlatba.platba_data_errors

    /**
     * Příznak, zda byla platba zkontrolována.
     * 
     *  @var boolean
     */
    platba_checked: false,

    /**
     * Chyby v detailu platby.
     * 
     *  @var {errorBody}
     */
    platba_error: null,

    /**
     * Příznak, zda platba je ve stavu "nedokončeno".
     */
    platba_state_unfinished: false
}

export const mutationsPlatba = {
    /**
     * Přepne čekající platbu na nedokončenou.
     * Prověří autenticitu parametrů: objednavka_id, platba_id
     * Dále prověří stav_platby_id.
     * 
     * @return {Promise}
     */
     changePaymentToUnfinished(params) {
        return new Promise((resolve, reject) => {
            sendRequest(
                '/api/payment/change-to-unfinished',
                params
            )
            .then(response => {
                state.platba_state_unfinished = true

                resolve(response.data)
            })
            .catch(errors => {
                reject(errors)
            })
        })
    },

    /**
     * Prověří na serveru platbu.
     * Prověří autenticitu parametrů: objednavka_id, platba_id
     * Dále prověří stav_platby_id.
     * 
     * @return {Promise}
     */
     checkPayment(params) {
        return new Promise((resolve, reject) => {
            //state.platba_checked = false

            sendRequest(
                '/api/payment/check',
                params
            )
            .then(response => {
                state.platba_checked = true

                resolve(response.data)
            })
            .catch(errors => {
                state.platba_checked = true

                reject(errors)
            })
        })
    },

    /**
     * Vrátí informace platbě.
     * 
     * @return {Promise}
     */
     infoPayment(params) {
        return new Promise((resolve, reject) => {
            state.platba_checked = false

            sendRequest(
                '/api/payment/info',
                params
            )
            .then(response => {
                resolve(response.data)
            })
            .catch(errors => {
                reject(errors)
            })
        })
    },

    processPaymentDataErrors(errors) {
        state.platba_error = errors[0]
    },

    /**
     * Načte data pro platbu převodem.
     * 
     * @return {Promise}
     */
     readTransfer(params) {
        return new Promise((resolve, reject) => {
            sendRequest(
                '/api/transfer/read',
                params
            )
            .then(response => {
                resolve(response.data)
            })
            .catch(errors => {
                reject(errors)
            })
        })
    },
}