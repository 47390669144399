import * as constants from '@/store/constants.js'
import { mutations, state } from '@/store'
import { sendRequest } from '@/lib/gms/api.js'

export const stateSetting = { 
    config: {
        application: { },

        /**
         * Statická data (konstanty).
         * 
         *  @var {object}
         * 
            {
                produkt: {
                    cena_ks: 389
                },
                zpusoby_dopravy: [
                    {"value":1,"text":"PPL","cena":100},
                    {"value":2,"text":"Osobní vyzvednutí Benešov","cena":0},
                    {"value":3,"text":"Osobní vyzvednutí Jestřebice","cena":0},
                    {"value":4,"text":"Osobní vyzvednutí Milevsko","cena":0},
                    {"value":5,"text":"Osobní vyzvednutí Tábor","cena":0},
                    {"value":6,"text":"Osobní vyzvednutí Vlašim","cena":0},
                    {"value":7,"text":"Osobní vyzvednutí Praha","cena":0}
                ],
                zpusoby_platby: [
                    {"value":1,"text":"Platební karta","cena":20},
                    {"value":2,"text":"Bankovní převod","cena":0}
                ]
            }
        */
        //data: null

    }
}

export const mutationsSetting = {
    /*readConfig( ) {
        mutations.setDataStateConfig(constants.DATA_STATE_PREPARING)

        sendRequest(
            '/api/read-config',
            { })
            .then(response => {
                //console.log(response)
      
                state.config = response.data

                mutations.setDataStateConfig(constants.DATA_STATE_READY)
            })
            .catch(errors => {
                console.log('readConfig.errors', errors)

                mutations.setDataStateConfig(constants.DATA_STATE_NOT_READY)
            })
    }*/

    readSetting( ) {
        mutations.setDataStateConfig(constants.DATA_STATE_PREPARING)

        sendRequest(
            '/api/read-setting',
            { })
            .then(response => {
                //console.log(response)
      
                state.config = response.data
                
                // Nastaví document.title
                document.title = state.config.application.title

                mutations.setDataStateConfig(constants.DATA_STATE_READY)
            })
            .catch(/*errors*/ ( ) => {
                //console.log('readSetting.errors', errors)

                mutations.setDataStateConfig(constants.DATA_STATE_NOT_READY)
            })
    }
}