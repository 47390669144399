export default [
    {
        name: 'comgate-payment-form',
        path: '/client/instructions',
        component: ( ) => import('@/views/comgate/PaymentFormView.vue')
    },
    {
        name: 'payment-paid',
        path: '/payment/paid',
        component: ( ) => import('@/views/payment/PaidView.vue')
    },
    {
        name: 'payment-cancelled',
        path: '/payment/cancelled',
        component: ( ) => import('@/views/payment/CancelledView.vue')
    },
    {
        name: 'payment-pending',
        path: '/payment/pending',
        component: ( ) => import('@/views/payment/PendingView.vue')
    },
    {
        name: 'payment-info',
        path: '/payment/info',
        component: ( ) => import('@/views/payment/InfoView.vue')
    },
    {
        name: 'payment-transfer',
        path: '/payment/transfer',
        component: ( ) => import('@/views/payment/TransferView.vue')
    },
]