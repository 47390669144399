import ability from '@/lib/acl/ability'
import Vue from 'vue'
import VueRouter from 'vue-router'
//import { canNavigate } from '@/lib/acl/route_protection'
import { /*state, mutations*/ } from '@/store'
//import { isUserLoggedIn/*, readLoggedInUser, getHomeRouteForLoggedInUser*/ } from '@/store/auth'

// Routy
import auth from './routes/auth'
import checkInForm from './routes/check_in_form'
import defaultView from './routes/default-view'
import error from './routes/error'
import gms from './routes/gms'
import orderForm from './routes/order_form'
import payment from './routes/payment'

/**
 * Testuje, zda lze na požadovanou routu navigovat
 * 
 * @param {*} to 
 * @returns {boolean}
 */ 
export const canNavigate = (to) => {
    //console.log("to", to)
    return to.matched.some((route) => {
        //console.log("route", route)
        
        // Test na definici ability
        if (route.meta != undefined
            && route.meta.action != undefined
            && route.meta.subject != undefined) {
                return ability.can(route.meta.action || 'read', route.meta.subject)
        } else return true
    })
}

// Ošetření chyby, viz.: ... Uncaught (in promise) NavigationDuplicated: Avoided redundant navigation to current location: "/user/my-account".at createRouter Error ...
/*const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}*/
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((error) => {
    // Only ignore NavigationDuplicated error
    if (error.name === 'NavigationDuplicated') {
      //console.error('NavigationDuplicated', error);
      return this.currentRoute;
    } else {
      console.error(error);
    }
  })
}

// Routy
const routes = [
  ... auth,
  ... checkInForm,
  ... defaultView,
  ... error,
  ... gms,
  ... orderForm,
  ... payment,
  //{ path: '/', redirect: { name: 'dashboard' } },
  {
    path: '*',
    redirect: 'error-404'
  }
]

// VueRouter
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Akce před zpracováním laždého požadavku
router.beforeEach((to, from, next) => {
  //const isLoggedIn = mutations.isUserLoggedIn( )

  // Historie (poslední zobrazená routa) 
  /*let router = {
    lastRoute: {
      fullPath: from.fullPath,
      hash: from.hash,
      meta: from.meta,
      name: from.name,
      params: from.params,
      path: from.path,
      query: from.query
    }
  }

  storage.setItem('router', router)*/

  // Ošetření stavu, kdy nelze zobrazit požadovaný pohled
  //console.log("to", to)
  //console.log("canNavigate", canNavigate(to))
  if (!canNavigate(to)) {
    // Pokud není přihlášen, přesměrování na přihlašovací formulář
    //if (!isLoggedIn) return next({ name: 'default-view' })

    // Pokud je přihlášen, přesměrování na "Not authorized"
    return next({ name: 'auth-not-authorized' })
  }

  // Redirect, pokud je uživatel přihlášen
  /*if (to.meta.redirect_if_logged_in) {
    next(getDefaultViewForUser( ))
  }*/

  return next( )
})

export default router